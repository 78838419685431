import timg1 from "../images/team/team_member_image_1.webp";
import timg2 from "../images/team/team_member_image_2.webp";
import tImg3 from "../images/about/abdul.png";
import timg4 from "../images/team/team_member_image_4.webp";
import timg5 from "../images/team/team_member_image_5.webp";
import timg6 from "../images/team/team_member_image_5.webp";

const Teams = [
  {
    Id: 1,
    tImg: timg1,
    name: "Humbulani Mulaudzi",
    slug: "Thee_Speaker",
    title: "Chief Marketing Officer",
    qualifications: ["Bsc CS", "Business Mngt", "Software Development"],
    profSkills: [
      "Product Management",
      "Product Development",
      "Marketing & Sales",
      "Customer Relations",
    ],
    countUps: [44, 87, 23, 92],
    socialLinks: {
      facebook: "https://facebook.com",
      twitter: "https://x.com",
      linkedIn: "https://linkedin.com",
      instagram: "https://instagram.com",
    },
  },
  {
    Id: 2,
    tImg: timg2,
    name: "Peace Munyayi",
    slug: "Peace_Thee_Coder",
    title: "Founder",
    qualifications: ["Bsc CS", "Statistics", "Web Development"],
    profSkills: [
      "Product Management",
      "Product Development",
      "Marketing & Sales",
      "Customer Relations",
    ],
    countUps: [65, 92, 83, 33],
    socialLinks: {
      facebook: "https://facebook.com",
      twitter: "https://x.com",
      linkedIn: "https://linkedin.com",
      instagram: "https://instagram.com",
    },
  },
  {
    Id: 3,
    tImg: tImg3,
    name: "Mduduzi Mahlangu",
    slug: "Mduduzi-Mahlangu",
    title: "Full-Stack Web Developer",
    qualifications: ["Web Development", "Software Development"],
    profSkills: [
      "Product Management",
      "Product Development",
      "Marketing & Sales",
      "Customer Relations",
    ],
    countUps: [8, 37, 13, 33],
    socialLinks: {
      facebook: "https://www.facebook.com/mduduziabdul.Mahlangu.7",
      twitter: "https://x.com",
      linkedIn: "http://linkedin.com/in/abdul-mahlangu",
      instagram: "https://instagram.com",
    },
  },
  {
    Id: 4,
    tImg: timg4,
    name: "Lebogang Lesufi",
    slug: "Lebogang-Lesufi",
    title: "Sales and marketing",
    qualifications: ["Bsc Marketing", "Public Works", "MS Advanced"],
    profSkills: [
      "Product Management",
      "Product Development",
      "Marketing & Sales",
      "Customer Relations",
    ],
    countUps: [66, 92, 13, 22],
    socialLinks: {
      facebook: "https://facebook.com",
      twitter: "https://x.com",
      linkedIn: "https://linkedin.com",
      instagram: "https://instagram.com",
    },
  },
  {
    Id: 5,
    tImg: timg5,
    name: "Simphiwe Mabena",
    slug: "Simphiwe-Mabena",
    title: "Sales and marketing",
    qualifications: ["Bsc Marketing", "Public Works", "MS Advanced"],
    profSkills: [
      "Product Management",
      "Product Development",
      "Marketing & Sales",
      "Customer Relations",
    ],
    countUps: [48, 47, 3, 62],
    socialLinks: {
      facebook: "https://facebook.com",
      twitter: "https://x.com",
      linkedIn: "https://linkedin.com",
      instagram: "https://instagram.com",
    },
  },
  {
    Id: 6,
    tImg: timg6,
    name: "Alina Ramusi",
    slug: "Alina-Ramusi",
    title: "Sales and marketing",
    qualifications: ["Bsc Marketing", "Public Works", "MS Advanced"],
    profSkills: [
      "Product Management",
      "Product Development",
      "Marketing & Sales",
      "Customer Relations",
    ],
    countUps: [54, 27, 43, 27],
    socialLinks: {
      facebook: "https://facebook.com",
      twitter: "https://x.com",
      linkedIn: "https://linkedin.com",
      instagram: "https://instagram.com",
    },
  },
];

export default Teams;
