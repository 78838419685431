import sImg3 from "../images/services/service_image_3.webp";
import sImg5 from "../images/services/service_image_5.webp";

import icon3 from "../images/icons/icon_monitor_2.svg";
import icon4 from "../images/icons/icon_phone.svg";

const Services = [
  {
    Id: 3,
    sImg: sImg3,
    title: "Website Development",
    slug: "Website-Development",
    thumb1: "Landing Page",
    thumb2: "Plugins",
    col: "col-lg-4",
    description:
      "Developing your trade, setting up new sales channels Your visa is ready...",
  },

  {
    Id: 5,
    sImg: sImg5,
    title: "UI/UX Design Services",
    slug: "UI-UX-Design-Services",
    thumb1: "Website",
    thumb2: "Mobile App",
    col: "col-lg-4",
    description:
      "Expert Guidance for a Seamless Immigration Journey Expert Guidance...",
  },

  {
    Id: 8,
    sImg: icon3,
    title: "Web App Design and Development",
    slug: "Web-Application-Design-and-Development",
    features: [
      "Web app development services",
      "Web portal development services",
      "Website development services",
      "Offshore web development",
    ],
  },
  {
    Id: 9,
    sImg: icon4,
    title: "Mobile App Design and Development",
    slug: "Mobile-App-Design-and-Development",
    features: [
      "Android development services",
      "iOS app development services",
      "Mobile application design services",
      "Enterprise mobile app development",
    ],
  },
];

export default Services;
